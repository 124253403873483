export default function (id) {
    let window = 'checklist.campo'
    !this.$uloc.window.get(window) && this.$uloc.window.new({
        wid: window,
        title: id ? `Campo #${id}` : 'Novo campo',
        width: '500',
        height: '60%',
        minHeight: '600px',
        minWidth: '700px',
        maxWidth: '900',
        backdrop: true,
        clickOutside: false,
        windowClass: 'erp-window',
        props: {
            id
        }
    }, () => import('../../components/window/gerenciamento-checklist/ChecklistCampo.vue'))
        .then((wid) => {
            console.log(wid)
            this.$uloc.window.listen(wid, {
                update: (wid, val) => {
                    console.log('Atualizado: ', val)
                    this.load?.()
                }
            })
        }) // return wid
}
