import http from '../../../../utils/services/http'

export const list = (limit, page, filtros) => {
  let url = '/api/checklist-campos?page=' + page + '&limit=' + limit + filtros
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const find = (id) => {
  let url = `/api/checklist-campos/${id}`
  return http.get(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const newChecklistCampo = (data) => {
  let url = `/api/checklist-campos`
  return http.post(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const updateChecklistCampo = (id, data) => {
  let url = `/api/checklist-campos/${id}`
  return http.patch(url, data)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export const deleteChecklistCampo = (id) => {
  let url = `/api/checklist-campos/${id}`
  return http.delete(url)
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(({response}) => {
      return Promise.reject(response)
    })
}

export default {
  list,
  find
}
