<script>
import Layout from '../../../layout/Layout'
import Menu from '../../menu-checklist'
import {list, deleteChecklistCampo} from '@/domain/remocao/services/gerenciamento-checklist/checklist-campos'
import {UCheckbox, UTable, UTd, UTh, UTr} from 'uloc-vue'
import {EBtnCircleStatus, EBtnTableOptions, ErpInput, ErpSField, ErpSelect} from 'uloc-vue-plugin-erp'
import MenuOptions from '../../../layout/context-menu/context-window-options'
import SearchFilter from '../../../layout/helpers/SearchFilter'
import DefaultMixin from '../../mixin'
import tableColumnsCache from "@/utils/tableColumnsCache"
import DefaultListMenu from "@/components/remocao/components/context/locais-list-menu"
import listOptions from "@/components/leiloes/windows/listOptions"
import ERow from "@/components/layout/components/Row"
import ECol from "@/components/layout/components/Col"
import {getTipoString} from "@/domain/remocao/helpers/gerenciamento-checklist"
import windowChecklistCampo from '../../windows/gerenciamento-checklist/checklistCampoWindow'

const LOCAL_STORAGE_TABLE_CACHE = 'checklistCampos.list'
const listStorage = tableColumnsCache(LOCAL_STORAGE_TABLE_CACHE, [
  {label: 'ID', name: 'id', active: true, sortable: true, ordering: 1},
  {label: 'Nome', name: 'nome', active: true, sortable: true, ordering: 1},
  {label: 'Obrigatório', name: 'obrigatorio', active: true, sortable: true, ordering: 1},
  {label: 'Tipo', name: 'tipo', active: true, sortable: true, ordering: 1},
  {label: 'Ativo', name: 'active', active: true, sortable: true, ordering: 1}
], 2)

let filters = {
  id: null,
  nome: null,
  cidadeOld: null,
  ufOld: null,
  active: [true]
}

export default {
  name: 'ListaChecklistCampos',
  mixins: [DefaultMixin],
  components: {
    ECol,
    ERow,
    DefaultListMenu,
    // DefaultAsyncSelect,
    // DateInput,
    SearchFilter,
    MenuOptions,
    Layout,
    UTable,
    UTh,
    UTr,
    UTd,
    UCheckbox,
    EBtnTableOptions,
    ErpInput,
    // ErpSelect,
    ErpSField,
    EBtnCircleStatus,
    ErpSelect
  },
  data() {
    return {
      listStorage: listStorage,
      listType: 'ativos',
      table: {
        busca: '',
        serverData: [],
        serverPagination: {
          page: 1,
          rowsNumber: 10, // specifying this determines pagination is server-side
          rowsPerPage: 20
        },
        columns: listStorage.map(o => {
          return {
            ...o,
            field: o.name,
            align: 'left'
          }
        }).concat([
          {
            name: 'options',
            required: true,
            label: '',
            field: 'options',
            sortable: false
          }
        ]),
        filter: '',
        filters: JSON.parse(JSON.stringify(filters)),
        selected: [],
        loading: false
      }
    }
  },
  computed: {
    menu() {
      return Menu
    },
    menuDashOptions() {
      return MenuOptions
    },
    visibledColumns() {
      return this.listStorage.filter(item => item.active).map(item => item.name)
    }
  },
  mounted() {
    this.load()
  },
  filters: {
    tipoCampoString (v) {
      return getTipoString(v)
    }
  },
  methods: {
    request({pagination, filter, format}) {

      let extraFilters = []

      this.table.filters.id && extraFilters.push(`&id=${this.table.filters.id}`)
      this.table.filters.nome && extraFilters.push(`&nome=${this.table.filters.nome}`)
      this.table.filters.tipo && extraFilters.push(`&tipo=${this.table.filters.tipo}`)

      if (Array.isArray(this.table.filters.status) && this.table.filters.status.length > 0) {
        extraFilters.push(`&status=${this.table.filters.status.join(',')}`)
      }

      if (Array.isArray(this.table.filters.statusInterno) && this.table.filters.statusInterno.length > 0) {
        extraFilters.push(`&statusInterno=${this.table.filters.statusInterno.join(',')}`)
      }
      extraFilters.push(`&active=${this.table.filters.active.join(',')}`)

      this.table.loading = true
      let filtros = encodeURI(`&sortBy=${pagination.sortBy}&descending=${pagination.sortBy ? (pagination.descending ? 'true' : 'false') : (this.listType === 'ativos' ? 'false' : true)}&search=${filter}${extraFilters.join('')}`)

      list(pagination.rowsPerPage, pagination.page, filtros, format)
          .then((response) => {
            let data = response.data

            this.table.serverPagination = pagination
            this.table.serverPagination.rowsNumber = data.total
            this.table.serverData = data.result
          })
          .catch(error => {
            this.alertApiError(error)
          })
          .finally(() => {
            this.table.loading = false
            this.loading = false
          })
    },
    load(format = null) {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.busca,
        format: format
      })
    },
    clearFilters() {
      this.table.filters = JSON.parse(JSON.stringify(filters))
    },
    novo() {
      console.log('New...')
      this.windowChecklistCampo()
    },
    pesquisar() {
      this.request({
        pagination: this.table.serverPagination,
        filter: this.table.filters.descricao || this.table.busca
      })
    },
    abrir(id) {
      this.windowChecklistCampo(id)
    },
    edit(id) {
      this.windowChecklistCampo(id)
    },
    excluir(id) {
      this.confirmarExclusao('Tem certeza que deseja excluir este campo? Todos os dados serão perdidos, sem possibilidade de recuperá-los.', 'excluir campo-' + id)
          .then(() => {
            this.$uloc.loading.show()
            this.deleteCampo(id)
          })
          .catch(() => {
          })
    },
    deleteCampo (id) {
      deleteChecklistCampo(id)
      .then(() => {
        this.load()
        this.$uloc.notify({
          type: 'positive',
          message: 'Campo excluído com sucesso!',
          icon: 'check'
        })
      })
      .catch(error => {
        this.alertApiError(error)
      })
      .finally(() => {
        this.$uloc.loading.hide()
      })
    },
    listOptions: listOptions,
    updateListDefinition(newConfig) {
      LocalStorage.set(LOCAL_STORAGE_TABLE_CACHE, newConfig)
      this.listStorage = newConfig
    },
    changeListType (v) {
      this.listType = v
      this.load()
    },
    imprimir () {
      this.load('html')
    },
    windowChecklistCampo
  },
  meta: {
    title: 'Checklist Campos',
    name: 'Checklist Campos'
  }
}
</script>

<template>
  <layout class="erp-list" :menu="menu" menu-style="compact" menu-active="checklist-campos">
    <div>
      <search-filter>
        <form id="leilaoFilter" class="m-t-sm">
          <e-row class="row erp-row def-m-r">

            <e-col style="max-width: 110px; min-width: 60px">
              <erp-s-field
                  view="tl"
                  label="ID"
              >
                <erp-input shortkey="F3" v-model="table.filters.id" @keydown.enter="pesquisar"/>
              </erp-s-field>
            </e-col>

            <e-col>
              <erp-s-field
                  view="tl"
                  label="Nome"
              >
                <erp-input shortkey="F4" v-model="table.filters.descricao" @keydown.enter="pesquisar"/>
              </erp-s-field>
            </e-col>
            <e-col>
              <erp-s-field
                  view="tl"
                  label="Ativo"
              >
                <erp-select multiple v-model="table.filters.active" :options="[
                    {label: 'Ativo', value: true},
                    {label: 'Inativo', value: false}
                ]" />
              </erp-s-field>
            </e-col>

          </e-row>

          <e-row class="row erp-row def-m-r">

            <e-col class="flex justify-end m-t-sm def-list-buttons" style="min-width: 300px">
              <div class="flex flex-center column m-r-xs">
                <u-btn class="pesquisar" label="Pesquisar" v-shortkey="['shift', 'enter']" @shortkey.native="pesquisar"
                       @click="pesquisar"/>
                <span class="shortcut">Shift + Enter</span>
              </div>
              <div class="flex flex-center column m-r-xs">
                <u-btn @click="clearFilters" class="limpar" label="Limpar Filtros" v-shortkey="['ctrl', 'shift', 'r']"
                       @shortkey.native="clearFilters"/>
                <span class="shortcut">Ctrl + Shift + R</span>
              </div>
              <div class="flex flex-center column">
                <u-btn @click="novo" class="cadastrar" label="Cadastrar" v-shortkey="['shift', 'n']"
                       @shortkey.native="novo"/>
                <span class="shortcut">Shift + N</span>
              </div>
            </e-col>
          </e-row>
        </form>
      </search-filter>
      <div class="erp-list">
        <div class="erp-list-top-btns">
          <div class="actives-btns">
          </div>
          <div class="end">
            <u-btn @click="listOptions" label="Configurar lista" no-caps size="sm" color="grey-4" class="text-grey-8"/>
          </div>
        </div>
        <u-table
            ref="table"
            color="primary"
            :data="table.serverData"
            :columns="table.columns"
            :visible-columns="visibledColumns"
            :filter="table.busca"
            row-key="id"
            :pagination.sync="table.serverPagination"
            @request="request"
            selection="multiple"
            :selected.sync="table.selected"
            :loading="table.loading"
            class="erp-table"
            :rows-per-page-options="[20, 50, 100]"
        >
          <!--<template slot="top-right" slot-scope="props">
          </template>-->
          <u-tr slot="header" slot-scope="props">
            <u-th auto-width>
              <u-checkbox
                  v-model="props.selected"
                  :indeterminate="props.partialSelected"
                  size="sm"
              />
            </u-th>
            <u-th v-for="col in props.cols" :key="col.name" :props="props">
              {{ col.label }}
            </u-th>
          </u-tr>

          <u-tr v-on:dblclick.native="abrir(props.row.id)" class="cursor-pointer" slot="body" slot-scope="props"
                :props="props" :class="{'u-table-item-destac': props.row.isToday}">
            <u-td auto-width>
              <u-checkbox color="primary" v-model="props.selected" size="xs"/>
            </u-td>
            <u-td style="max-width: 40px; min-width: 40px; width: 40px" key="id" :props="props">
              {{ props.row.id }}
            </u-td>
            <u-td key="nome" :props="props">
              {{ props.row.nome }}
            </u-td>
            <u-td key="obrigatorio" :props="props">
              <span v-if="props.row.obrigatorio" class="text-green">
                <e-btn-circle-status status="positive" class="text-sm"></e-btn-circle-status>
                Sim
              </span>
              <span class="text-red" v-else>
                <e-btn-circle-status status="negative" class="text-sm"></e-btn-circle-status>
                Não
              </span>
            </u-td>
            <u-td key="tipo" :props="props">
              {{ props.row.tipo | tipoCampoString }}
            </u-td>
            <u-td key="categoria" :props="props">
              {{ (props.row.categoria || {}).nome || 'Sem categoria definida' }}
            </u-td>
            <u-td key="active" :props="props">
              <div v-if="props.row.active" class="text-positive">Ativo</div><div class="text-negative" v-else>Desativado</div>
            </u-td>
            <u-td class="text-center" key="options" :props="props">
              <e-btn-table-options>
                <menu-options>
                  <default-list-menu @abrir="abrir(props.row.id)" @edit="edit(props.row.id)"
                                     @excluir="excluir(props.row.id)" :leilao="props.row"/>
                </menu-options>
              </e-btn-table-options>
            </u-td>
          </u-tr>
        </u-table>
      </div>
    </div>
  </layout>
</template>

<style lang="stylus" src="../../assets/default.styl"></style>
